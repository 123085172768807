export default function (value) {
  if (!value) {
    return value
  }
  switch (value) {
  case 'EMAIL':
    return 'E-Mail'
  case 'SMS':
    return 'SMS'
  case 'FILE':
    return 'Datei'
  case 'EXPORT_MOBILITY':
    return 'Export'
  default:
    return ''
  }
}
