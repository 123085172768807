<template>
  <div>
    <template v-for="(squashedLog, index) in squashedLogs">
      <div
        :key="index+'log-card'"
        class="card mb-3 pb-3"
      >
        <template v-if="squashedLog.orderLogs.length === 0">
          <component
            :is="squashedLog.component"
            :big="big"
            :order-log="squashedLog.orderLogs[0]"
          />
        </template>
        <template v-else>
          <div class="card-body">
            {{ squashedLog.orderLogs.length }} Mal {{ squashedLog.type | formatOrderLogType }}
          </div>

          <template v-if="squashedLog.open">
            <template v-for="(orderLog, logIndex) in squashedLog.orderLogs">
              <component
                :is="squashedLog.component"
                :key="logIndex+'log'"
                :big="big"
                :order-log="orderLog"
              />
            </template>
          </template>
          <template v-else>
            <component
              :is="squashedLog.component"
              :big="big"
              :order-log="squashedLog.orderLogs[0]"
            />
          </template>

          <button
            v-if="squashedLog.orderLogs.length > 1"
            class="btn btn-link"
            @click="squashedLog.open = !squashedLog.open"
          >
            {{ squashedLog.open ? 'schließen' : 'weitere' }}
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import EmailLog from './Logs/EmailLog'
import FileLog from './Logs/FileLog'
import SmsLog from './Logs/SmsLog'
import MobilityExportLog from './Logs/MobilityExportLog'
import formatOrderLogType from '@/filters/formatOrderLogType.js'

export default {
  name: 'OrderLogs',
  filters: {
    formatOrderLogType
  },
  props: {
    big: {
      default: false,
      type: Boolean
    },
    orderLogs: {
      default: undefined,
      type: Array
    }
  },
  data () {
    return {
      squashedLogs: []
    }
  },
  watch: {
    orderLogs: {
      handler: 'squashLogs',
      immediate: true
    }
  },
  methods: {
    squashLogs () {
      this.squashedLogs = this.orderLogs.reduce((prev, cur, index) => {
        if (index === 0 || prev[prev.length - 1].type !== cur.type) {
          prev.push({
            component: this.getOrderLogComponent(cur),
            type: cur.type,
            orderLogs: [cur],
            open: false
          })
        }
        if (index >= 1 && prev[prev.length - 1].type === cur.type) {
          prev[prev.length - 1].orderLogs.push(cur)
        }
        return prev
      }, [])
    },
    getOrderLogComponent (orderLog) {
      switch (orderLog.type) {
      case 'EMAIL': return EmailLog
      case 'FILE': return FileLog
      case 'SMS': return SmsLog
      case 'EXPORT_MOBILITY': return MobilityExportLog
      }
    }
  }
}
</script>
