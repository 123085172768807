<template>
  <div>
    <LogWrapper
      :order-log="orderLog"
      badge-class="bg-success-light"
      icon-class="fa-envelope text-success"
      type-badge-class="badge-success text-white"
    >
      <template v-if="!orderLog.error">
        <div
          v-for="(mail, mailIndex) in orderLog.data"
          :key="'mail'+mailIndex"
        >
          <template v-if="mail">
            E-Mail mit dem Betreff <span class="font-weight-bold">{{ mail.envelope.subject }}</span> versendet an
            <span
              class="font-weight-bold"
            >{{ formatRecievers(mail.envelope.to) }} <span /></span>
          </template>
        </div>
      </template>
      <template v-else>
        <div>
          <span class="font-weight-bold">Fehler:</span> E-Mail wurde nicht versendet
        </div>
      </template>
    </LogWrapper>
  </div>
</template>

<script>
import LogWrapper from './LogWrapper.vue'
export default {
  name: 'EmailLog',
  components: {
    LogWrapper
  },
  props: {
    orderLog: {
      type: Object,
      required: true
    },
    big: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    formatRecievers (receivers) {
      return receivers.join(', ')
    }
  }
}
</script>

<style>

</style>
