<template>
  <LogWrapper
    :order-log="orderLog"
    badge-class="bg-info-light"
    icon-class="fa-comment-dots text-info"
    type-badge-class="badge-info text-white"
  >
    <template v-if="!orderLog.error">
      <div
        v-for="sms of orderLog.data"
        :key="sms.sid"
      >
        SMS versendet an {{ sms.to }} am {{ sms.createdAt | formatDate('DD.MM.YYYY') }}
      </div>
    </template>
    <template v-else>
      <div
        v-for="sms of orderLog.data"
        :key="sms.sid"
      >
        <div>
          <span class="font-weight-bold">Fehler:</span> SMS wurde nicht versendet
        </div>
        <div v-if="sms.code === 21211">
          Ungültige Empfängernummer
        </div>
      </div>
    </template>
    <div
      v-for="sms of orderLog.data"
      :key="sms.sid"
    >
      SMS versendet an {{ sms.to }} am {{ sms.createdAt | formatDate('DD.MM.YYYY') }}
    </div>
  </LogWrapper>
</template>

<script>
import LogWrapper from './LogWrapper'
import formatDate from '@/filters/formatDate'
export default {
  name: 'SmsLog',
  filters: {
    formatDate
  },
  components: {
    LogWrapper
  },
  props: {
    orderLog: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
