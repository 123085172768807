<template>
  <div class="card-body">
    <div
      class="form-row align-items-center"
    >
      <div class="col-auto">
        <div
          class="order-icon-wrapper"
          :class="badgeClass"
        >
          <i
            class="far fa-lg"
            :class="iconClass"
          />
          <span
            class="badge badge-pill classtext-white"
            :class="typeBadgeClass"
          >
            {{ orderLog.type | formatOrderLogType }}
          </span>
        </div>
      </div>
      <div class="col">
        <slot />
      </div>
      <div class="col-auto">
        <p class="mb-0 d-inline">
          {{ orderLog.createdAt | formatDate('DD.MM.YYYY HH:mm:ss') }}
        </p>
      </div>
      <div class="col-auto">
        <router-link
          v-if="$route.meta.isGlobalLogList"
          :to="{name: 'OrderDetail', params: {orderId: orderLog.orderId}}"
          class="btn btn-link"
        >
          <i class="far fa-chevron-right" /> zur Police
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from '@/filters/formatDate.js'
import formatOrderLogType from '@/filters/formatOrderLogType.js'
export default {
  name: 'LogWrapper',
  filters: {
    formatDate,
    formatOrderLogType
  },
  props: {
    orderLog: {
      type: Object,
      required: true
    },
    badgeClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    typeBadgeClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.order-icon-wrapper{
  margin-right: 5px;
  border-radius: 50%;
  $size: 50px;
  width: $size;
  height: $size;
  position: relative;
  display: inline-flex;
  .badge{
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
  i{
    margin: auto;
  }
}
</style>
