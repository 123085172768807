<template>
  <div>
    <ca-header heading="Logs" />
    <form
      class="container-fluid px-4 pt-4"
      @submit.prevent="fetchData"
    >
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <div class="form-row">
                <div class="col-12 col-md-4">
                  Filter
                </div>
                <div class="col-12 col-md-8">
                  <div class="filter-wrapper">
                    <template v-for="typeSelectOption in typeSelectOptions">
                      <button
                        :key="typeSelectOption.type"
                        class="btn mr-2"
                        :class="{'btn-primary': isInTypeFilter(typeSelectOption), 'btn-white': !isInTypeFilter(typeSelectOption)}"
                        @click="toggleTypeFilter(typeSelectOption)"
                      >
                        {{ typeSelectOption.name }}
                      </button>
                    </template>
                  </div>
                </div>
              </div>
              <basic-input
                v-model="userInputField"
                placeholder="Benutzer"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Suche nach Benutzern"
                type="search"
              >
                <template #append>
                  <button
                    type="submit"
                    class="btn btn-primary"
                  >
                    <i class="far fa-search" />
                  </button>
                </template>
              </basic-input>
              <basic-datepicker
                v-model="date.from"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                format="DD.MM.YYYY HH:mm"
                label="Zeitraum von:"
              />
              <basic-datepicker
                v-model="date.to"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                format="DD.MM.YYYY HH:mm"
                label="Zeitraum bis:"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="input-group-append" />
      <order-logs
        :order-logs="orderLogs"
        :big="true"
      />
    </form>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import OrderLogs from '@/components/OrderLogs.vue'
import BasicInput from '../../components/BaseComponents/BasicInput'
import BasicDatepicker from '../../components/BaseComponents/BasicDatepicker'
import getWarrentyNumber from '../../helpers/getWarrentyNumber'

export default {
  name: 'OrderLog',
  components: {
    OrderLogs,
    CaHeader,
    BasicInput,
    BasicDatepicker
  },
  data: () => {
    return {
      userInputField: '',
      date: {
        from: undefined,
        to: undefined
      },
      typeFilter: [
        { name: 'E-mail', type: 'EMAIL' },
        { name: 'SMS', type: 'SMS' },
        { name: 'Datei', type: 'FILE' },
        { name: 'Export', type: 'EXPORT_MOBILITY' }
      ],
      orderLogs: null,
      typeSelectOptions: [
        { name: 'E-mail', type: 'EMAIL' },
        { name: 'SMS', type: 'SMS' },
        { name: 'Datei', type: 'FILE' },
        { name: 'Export', type: 'EXPORT_MOBILITY' }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user'].user
    },
    warrentyNumber () {
      return getWarrentyNumber(this.orderLog.order)
    },
    baseQuery () {
      const baseQuery = {}
      if (this.typeFilter.length > 0) {
        baseQuery.type = {
          $in: this.typeFilter.map(typeOption => typeOption.type)
        }
      }

      if (this.date.from || this.date.to) baseQuery.$and = []
      if (this.date.from) {
        baseQuery.$and.push({
          createdAt: {
            $gte: this.date.from
          }
        })
      }
      if (this.date.to) {
        baseQuery.$and.push({
          createdAt: {
            $lte: this.date.to
          }
        })
      }

      if (this.userInputField !== '') {
        baseQuery.$or = [
          {
            'user.firstname': {
              $regex: this.userInputField,
              $options: 'i'
            }
          },
          {
            'user.lastname': {
              $regex: this.userInputField,
              $options: 'i'
            }
          }
        ]
      }
      return baseQuery
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.orderLogs = (await this.$store.dispatch('order-log-list/find', {
          query: this.baseQuery
        })).data
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    getStatusClass (statusCode) {
      let statusClass = ''
      switch (statusCode) {
      case 3:
      case 4:
      case 5: statusClass = 'text-muted'; break
      }
      return statusClass
    },
    settypeFilter (statusCode) {
      this.typeFilter = statusCode
    },
    toggleTypeFilter (option) {
      const existingFilterIndex = this.typeFilter.findIndex(filter => filter.type === option.type)
      if (existingFilterIndex !== -1) {
        this.typeFilter.splice(existingFilterIndex, 1)
      } else {
        this.typeFilter.push(option)
      }
    },
    isInTypeFilter (option) {
      return this.typeFilter.some(filter => filter.type === option.type)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
