<template>
  <LogWrapper
    :order-log="orderLog"
    badge-class="bg-secondary"
    icon-class="fa-file-alt text-primary"
    type-badge-class="badge-primary"
  >
    <template v-for="(file, fileIndex) in orderLog.files">
      <div
        :key="'file'+fileIndex"
        class="mr-3"
        :class="{'d-inline': big}"
      >
        <SignedDownloadLink
          v-if="file"
          :file="file"
        >
          <template #default="{link}">
            <a
              :href="link"
              :download="file.name"
            ><i class="far fa-download" /> {{ file.name }}</a>
          </template>
        </SignedDownloadLink>
      </div>
    </template>
  </LogWrapper>
</template>

<script>
import LogWrapper from './LogWrapper'
import SignedDownloadLink from '@/components/SignedDownloadLink'
export default {
  name: 'FileLog',
  components: {
    LogWrapper,
    SignedDownloadLink
  },
  props: {
    orderLog: {
      type: Object,
      required: true
    },
    big: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>

</style>
