<template>
  <LogWrapper :order-log="orderLog">
    Vertrag wurde an {{ name }} übertragen
  </LogWrapper>
</template>

<script>
import LogWrapper from './LogWrapper'
export default {
  name: 'MobilityExportLog',
  components: {
    LogWrapper
  },
  props: {
    orderLog: {
      type: Object,
      required: true
    }
  },
  computed: {
    name () {
      if (new Date(this.orderLog.createdAt) < new Date('2020-06-29')) return 'EuropeAssistance'
      return 'Automobilclub von Deutschland'
    }
  }
}
</script>

<style>

</style>
